import "@lottiefiles/lottie-player";

import Tags from './components/tags';
import Toggle from './components/readmore';
import Ecoswitch from './components/ecoswitch';
import Languageswitch from './components/languageswitch';
import Menu from './components/menu';
import Lottie from './components/lottie';
import Image from './components/image';
import InView from './components/in-view';
import AnimatedTitle from './components/title';
import AnimatedTitleFirst from './components/title-first';

import { gallery } from './components/block-slider';

const tagsElement = document.querySelectorAll( '[data-filter]' );
const readmoreElement = document.querySelector( '[data-read-more]' );
const ecoswitchElements = document.querySelectorAll( '[data-ecoswitch]' );
const languageswitchElement = document.querySelector( '[data-languageswitch]' );
const menuElement = document.querySelector( '[data-menu]' );
const sliderBlockElement = document.querySelector( '[data-block-slider]' );
const lottieElements = document.querySelectorAll( '[data-lottie]' );
const imageElements = document.querySelectorAll( '[data-image]' );
const animatedTitleElements = document.querySelectorAll( '[data-title]' );
const animatedTitleFirstElements = document.querySelectorAll( '[data-title-first]' );


if ( tagsElement ) {
    const tags = new Tags( tagsElement );
}

if ( readmoreElement ) {
    const toggle = new Toggle( readmoreElement );
}

if ( ecoswitchElements ) {
    ecoswitchElements.forEach(element => {
        const ecoswitch = new Ecoswitch( element );
    });
}

if ( languageswitchElement ) {
    const languageswitch = new Languageswitch( languageswitchElement );
}

if ( menuElement ) {
    const menu = new Menu( menuElement );
}

if ( lottieElements ) {
    lottieElements.forEach(element => {
        const lottie = new Lottie( element );
    });
}

if ( imageElements ) {
    imageElements.forEach(element => {
        const image = new Image( element );
    });
}

if ( animatedTitleElements ) {
    console.log("TitleElements");
    animatedTitleElements.forEach(element => {
        const title = new AnimatedTitle( element );
    });
}

if ( animatedTitleFirstElements ) {
    console.log("TitleFirstElements");
    animatedTitleFirstElements.forEach(element => {
        const titleFirst = new AnimatedTitleFirst( element );
    });
}


if ( sliderBlockElement ) {
    gallery.init( sliderBlockElement );
}

const inView = new InView( '[data-in-view]' );
