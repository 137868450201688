export default class Tags {

    constructor( element ) {
        this.element = element;

        this.filters = element;
        this.cases = document.querySelector('[data-cases]');

        this.addEventListeners();
    }

    addEventListeners() {

        this.filters.forEach(filter => {
            filter.addEventListener( 'click', ( event ) => { this.handleClick( event, filter ); } ); 
        });

    }

    handleClick( event, filter ) {

        this.filters.forEach( filter => {
            filter.classList.remove("active");
        })

        event.preventDefault();
        filter.classList.add('active');
        var tag = filter.getAttribute( 'data-filter');
        this.fetchProjects(tag);

    }

    async fetchProjects(tag) {
        let url = `${window.location.href}.json/tag:${tag}`;

        try {
          const response       = await fetch(url);
          const { html } = await response.json();
          this.cases.innerHTML = html;

        } catch (error) {
          console.log('Fetch error: ', error);
        }
      }
}