
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import Lenis from '@studio-freight/lenis';

export default class AnimatedTitle {

    constructor( element ) {
        console.log('Element:', element);
        gsap.registerPlugin( ScrollTrigger );

        this.element = element;
        this.margin;
        
        this.init();
        this.addEventListeners();

        
    }

    init() {
        
        // const lenis = new Lenis({
        //     duration: 1.2,
        //     // easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
        //     // wrapper: "body"
        //   })
          
        // lenis.on('scroll', ScrollTrigger.update)

        // gsap.ticker.add((time)=>{
        // lenis.raf(time * 1000)
        // })

        // gsap.ticker.lagSmoothing(0)

        //   function raf(time) {
        //     lenis.raf(time)
        //     requestAnimationFrame(raf)
        //   }
          
        //   requestAnimationFrame(raf)

        this.margin = 0.25 * this.element.clientWidth;
        
            gsap.to(this.element, { 
            x: () => -1 * Math.abs(this.element.scrollWidth - this.element.clientWidth + this.margin),
            scrollTrigger: {
            trigger: this.element,
            // markers: true,
            duration: 2.2,
            // pin: ".wrapper",
            ease: "power1.inOut",
            // pinnedContainer: '.wrapper',
            start: "top 60%",
            scrub: 4,
            invalidateOnRefresh: true
            }
            })
        }
    

    addEventListeners() {
        window.addEventListener( 'resize', (event) => {
            ScrollTrigger.refresh();
        });
        window.addEventListener( 'orientationChange', (event) => {
            ScrollTrigger.refresh();
        });
    }
}
