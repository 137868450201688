export default class Toggle {

    constructor( element ) {
        this.element = element;

        this.more = document.querySelector('[data-read-more]');
        this.moretext = document.querySelector('[data-more]');

        this.addEventListeners();
    }

    addEventListeners() {

        this.more.addEventListener( 'click', ( event ) => { this.handleClick( event ); } ); 

    }

    handleClick( event ) {
           
        this.more.classList.toggle('active');
        this.moretext.classList.toggle('active');

    }


}