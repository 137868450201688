import Swiper, { Navigation, Autoplay } from 'swiper';

class Gallery {

    constructor() {
        this.element;
        this.breakpoint = window.matchMedia("(max-width: 767px)")
    }

    init( element ) {

        this.element = element;
        this.gswiper;

        this.initGallery();
        this.initBreakpointchecker();
        this.addEventListeners();

    }

    initGallery() {
        this.gswiper = new Swiper( '[data-block-slider]', {
            modules: [ Navigation, Autoplay ],
            direction: 'horizontal',
            slidesPerView: 'auto',
            loop: true,
            // autoplay: {
            //     delay: 3500,
            //     disableOnInteraction: false,
            // },
            navigation: {
                nextEl: '.gallery-next',
                prevEl: '.gallery-prev',
            },
            // breakpoints: {
            //     480: {
            //         slidesPerView: 3,
            //     },
            // },
        } );
    }

    initBreakpointchecker() {
        if ( this.breakpoint.matches === true ) {
            // clean up old instances and inline styles when available
            if ( this.gswiper !== undefined ) {
                console.log('destroy');
                this.gswiper.destroy( true, true );
            }
         // else if a small viewport and single column layout needed
         } else if ( this.breakpoint.matches === false ) {
            // fire small viewport version of swiper
            this.initGallery();
         }
    }

    addEventListeners() {
        window.addEventListener( 'resize', (event) => {
            this.initBreakpointchecker();
        });
        window.addEventListener( 'orientationChange', (event) => {
            this.initBreakpointchecker();
        });
    }


}


export const gallery = new Gallery();