
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default class AnimatedTitleFirst {

    constructor( element ) {

        gsap.registerPlugin( ScrollTrigger );

        this.element = element;
        this.margin;
        
        this.init();
        this.addEventListeners();

        
    }

    init() {

        this.margin = 0.25 * this.element.clientWidth;
        
        if (window.innerWidth < 768) {
            gsap.to(this.element, { 
                x: () => -1 * Math.abs(this.element.scrollWidth - this.element.clientWidth + this.margin),
                scrollTrigger: {
                trigger: this.element,
                // markers: true,
                duration: 2.2,
                // pin: ".wrapper",
                ease: "power1.inOut",
                // pinnedContainer: '.wrapper',
                start: "top 130px",
                scrub: 4,
                invalidateOnRefresh: true
                }
            })
        }

        else {
            gsap.to(this.element, { 
            x: () => -1 * Math.abs(this.element.scrollWidth - this.element.clientWidth + this.margin),
            scrollTrigger: {
            trigger: this.element,
            // markers: true,
            duration: 2.2,
            // pin: ".wrapper",
            ease: "power1.inOut",
            // pinnedContainer: '.wrapper',
            start: "top 200px",
            scrub: 4,
            invalidateOnRefresh: true
            }
            })
        }
    }

    addEventListeners() {
        window.addEventListener( 'resize', (event) => {
            ScrollTrigger.refresh();
        });
        window.addEventListener( 'orientationChange', (event) => {
            ScrollTrigger.refresh();
        });
    }
}
