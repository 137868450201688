export default class Languageswitch {

    constructor( element ) {
        this.element = element;


        this.addEventListeners();
        this.breakpoint = window.matchMedia("(max-width: 1024px)")

    }


    addEventListeners() {
        this.element.addEventListener('mouseenter', ( event ) => { this.handleHover( event ); } );
        this.element.addEventListener('mouseleave', ( event ) => { this.handleHover( event ); } );

    }

    handleHover( event ) {
        var ltoggles = this.element.querySelectorAll('li');

        if ( this.breakpoint.matches === false ) {
            ltoggles.forEach( ( ltoggle ) => {
                ltoggle.classList.toggle('active');
            })
         }
        
    }


}